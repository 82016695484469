import axios from 'axios';
import {Message} from "element-ui";
import router from "../router";

let base = '/api'

axios.defaults.baseURL = base
//请求拦截器
axios.interceptors.request.use(config => {
        //如果存在token，请求携带这个token
        const tokenStr = window.sessionStorage.getItem('tokenStr');
        if (tokenStr) {
            config.headers['Authorization'] = window.sessionStorage.getItem('tokenStr');
        }
        return config;
    }, error => {
        console.log(error);
    }
)

//响应拦截器
axios.interceptors.response.use(success => {
//业务逻辑错误200调用成功
    if (success.status && success.status === 200) {
        //500业务逻辑错误、401未登录、403权限禁止
        if (success.data.code === 500 || success.data.code === 401 || success.data.code === 403) {
            Message.error({message: success.data.message});
            return;
        }
        if (success.data.message && success.data.message !== "") {
            Message.success({message: success.data.message});
        }
    }
    return success.data;
}, error => {
    //504服务器有问题、404找不到
    if (error.response.code === 504 || error.response.code === 404) {
        Message.error({message: '服务器被吃了( ╯□╰ )'});
    } else if (error.response.code === 403) {
        Message.error({message: '权限不足，请联系管理员'});
    } else if (error.response.code === 401) {
        Message.error({message: '用户名或密码不正确'});
        router.replace('/');
    } else {
        if (error.response.data.message) {
            Message.error({message: error.response.data.message});
        } else {
            Message.error({message: '错误未知'});
        }
    }
})

// let base = 'http://116.205.163.180:8081'

//传送json格式的post请求

export const postRequest = (url, params) => {
    return axios({
        method: "POST",
        url: `${url}`,
        data: params
    })
}

//传送json的put请求
export const putRequest = (url, params) => {
    return axios({
        method: 'PUT',
        url: `${url}`,
        data: params
    })
}

//传送json的get请求
export const getRequest = (url, params) => {
    return axios({
        method: 'GET',
        url: `${url}`,
        data: params
    })
}

//传送json的delete请求
export const deleteRequest = (url, params) => {
    return axios({
        method: 'DELETE',
        url: `${url}`,
        data: params
    })
}