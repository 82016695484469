<template>
  <div>
    <el-date-picker
        v-model="dateValue"
        align="left"
        type="date"
        placeholder="请选择要对比的日前"
        value-format="yyyy-MM-dd"
        :picker-options="pickerOptions">
    </el-date-picker>
    <el-table
        :data="users"
        v-loading="loading"
        border
        stripe
        style="margin-top: 1%;margin-bottom: 2%;">
      <el-table-column
          prop="id"
          label="id"
          width="90"
          v-if="false">
      </el-table-column>
      <el-table-column
          prop="username"
          label="用户名"
          width="100"
          align="center">
      </el-table-column>
      <el-table-column
          prop="active"
          label="是否启用"
          width="100"
          align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.active" type="success" effect="dark">Enable</el-tag>
          <el-tag v-else type="danger" effect="dark">Disable</el-tag>
        </template>
      </el-table-column>
      <el-table-column
          prop="count"
          label="总勾画量"
          width="80"
          align="center">
      </el-table-column>
      <el-table-column
          label="操作"
          width="200"
          align="center">
        <template slot-scope="scope">
          <el-button type="primary" @click="getMaskMatchDay(scope.row)" size="small">查看天对比</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "dailyAcc",
  data() {
    return {
      dateValue: '',
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < new Date("2024-08-15").getTime() - 8.64e7;
        },
      },
      mUser: JSON.parse(window.sessionStorage.getItem('user')),
      users: null,
      loading: false,
    }
  },
  methods: {
    getMaskMatchDay(row) {
      if (this.dateValue === '') {
        this.$message({
          message: '你还没有选择日期！',
          type: 'error'
        })
        return;
      }
      axios.get('/mzfCheck/getMaskMatchDay', {
        params: {
          "uUserId": row.id,
          "date": this.dateValue
        }
      }).then(res => {
        console.log(res)
        if (!isNaN(res.obj)) {
          this.$notify({
            title: this.dateValue + ' ' + row.username + ' 的匹配度',
            message: res.obj,
            duration: 0,
            type: 'success',
            offset: 46
          });
        } else {
          this.$notify({
            title: '提示',
            message: '该用户今日还没有标注验证图片',
            type: 'warning',
            duration: 1500,
            offset: 46
          })
        }
      }).catch(() => {
        this.$notify({
          title: '提示',
          message: '莫则菲还没有标记与可以与当前选中用户相匹配的验证图片',
          type: 'warning',
          duration: 4500,
          offset: 46
        })
      })
    },
    getUsers() {
      this.loading = true;
      this.getRequest('/user/mzfGetAllUsers').then(res => {
        this.users = res.obj;
        this.loading = false;
      })
    },
    getCurrentDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0'); // 月份从 0 开始，所以要加 1，并用 padStart 确保是两位数
      const day = String(today.getDate()).padStart(2, '0');
      this.dateValue = `${year}-${month}-${day}`;
    },
  },
  created() {
    this.getUsers();
    this.getCurrentDate();
  }
}
</script>


<style scoped>

</style>