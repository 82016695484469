import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'
import {postRequest} from "./utils/api"
import {getRequest} from "./utils/api"
import {putRequest} from "./utils/api"
import {deleteRequest} from "./utils/api"
import {initMenu} from "@/utils/menus";
import 'font-awesome/css/font-awesome.css'
import {downloadRequest} from "@/utils/download";

Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.prototype.postRequest = postRequest;
Vue.prototype.getRequest = getRequest;
Vue.prototype.putRequest = putRequest;
Vue.prototype.deleteRequest = deleteRequest;
Vue.prototype.downloadRequest = downloadRequest;

Vue.prototype.$base = '/api'

// router.beforeEach((to, from, next) => {
//     if (window.sessionStorage.getItem('tokenStr')) {
//         //菜单注释
//         // initMenu(router, store, window.sessionStorage.getItem('type'));
//         if (!window.sessionStorage.getItem('user')) {
//             //判断用户信息是否存在
//             return getRequest('/user/info').then(resp => {
//                 if (resp) {
//                     //存入用户信息
//                     window.sessionStorage.setItem('user', JSON.stringify(resp));
//                     next();
//                 }
//             })
//         }
//         //没有则不会跳往to
//         next();
//     } else {
//         if (to.path === '/') {
//             next();
//         } else {
//             next('/?redirect=' + to.path);
//         }
//     }
// });
router.beforeEach((to, from, next) => {
    if (window.sessionStorage.getItem('tokenStr')) {
        //菜单注释
        // initMenu(router, store, window.sessionStorage.getItem('type'));
        if (!window.sessionStorage.getItem('user')) {
            //判断用户信息是否存在
            return getRequest('/user/info').then(resp => {
                if (resp) {
                    //存入用户信息
                    window.sessionStorage.setItem('user', JSON.stringify(resp));
                    next();
                }
            })
        } else {
            //没有则不会跳往to
            next();
        }
    } else {
        if (to.path === '/login' || to.path === '/') {
            // if (to.path === '/') {
            next();
        } else {
            next('/login?redirect=' + to.path);
        }
    }
});

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
