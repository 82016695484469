<template>
  <div>
    <div>审核员你好！</div>
    <!-- 使用 v-if、v-else-if 和 v-else 来决定显示哪一段内容 -->
    <div v-if="mywork === null">正在加载...</div>
    <div v-else-if="isEmptyWork">你还没有审核过图片</div>
    <div v-else>
      {{ mywork[0][0] }}
      <hr>
      <div v-for="(item, index) in mywork[1]" :key="index">
        {{ item }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReviewerWork",
  data() {
    return {
      userId: 0,
      mywork: null,
    };
  },
  computed: {
    // 判断 mywork 的内容是否与 [ [], [] ] 相同
    isEmptyWork() {
      if (!this.mywork || this.mywork.length !== 2) return false;
      return this.mywork.every(subArray => Array.isArray(subArray) && subArray.length === 0);
    }
  },
  methods: {
    getMyWork(id) {
      this.getRequest(`/user/getCorrectorCompleteState/${id}`).then(res => {
        this.mywork = res;
      });
    },
  },
  mounted() {
    this.userId = JSON.parse(window.sessionStorage.getItem('user')).id;
    this.getMyWork(this.userId);
  },
}
</script>

<style scoped>

</style>
