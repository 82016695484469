<template>
  <div>
    <el-card class="box-card" style="width: 400px;">
      <div slot="header" class="clearfix">
        <span>{{ admin.username }}</span>
      </div>
      <div class="cardForUser">
        <div style="display: flex;justify-content: center">
          <img src="https://c-ssl.duitang.com/uploads/blog/202402/27/oVS0eQDmHgWn985.jpeg" alt=""
               style="height: 100px;width: 100px;border-radius: 50px"></div>
        <div> Username:
          <el-tag size="small">{{ admin.username }}</el-tag>
        </div>
        <!--        <div> Email:-->
        <!--          <el-tag size="small">{{ admin.email }}</el-tag>-->
        <!--        </div>-->
        <!--        <div> Phone:-->
        <!--          <el-tag size="small">{{ admin.phone }}</el-tag>-->
        <!--        </div>-->
        <div style="display: flex;justify-content: space-around;margin-top: 10px;">
          <!--          <el-button type="primary" size="small" @click="showUpdateAdminInfoView">Modify information</el-button>-->
          <el-button type="danger" size="small" @click="showUpdatePasswordView">Change password</el-button>
        </div>
      </div>
    </el-card>
    <el-dialog
        title="Modify user information"
        :visible.sync="dialogVisible"
        width="30%">
      <div>
        <table>
          <!--          <tr style="margin-top: 5px">-->
          <!--            <td>-->
          <!--              Email：-->
          <!--            </td>-->
          <!--            <td>-->
          <!--              <el-tooltip content="不可更改！" placement="top">-->
          <!--                <el-input size="small" v-model="admin2.email" disabled></el-input>-->
          <!--              </el-tooltip>-->
          <!--            </td>-->
          <!--          </tr>-->
          <tr style="margin-top: 5px">
            <td>
              Username：
            </td>
            <td>
              <el-tooltip content="不可更改！" placement="top">
                <el-input size="small" v-model="admin2.username"></el-input>
              </el-tooltip>
            </td>
          </tr>
          <!--          <tr style="margin-top: 5px">-->
          <!--            <td>-->
          <!--              电话号码：-->
          <!--            </td>-->
          <!--            <td>-->
          <!--              <el-input size="small" v-model="admin2.telephone"></el-input>-->
          <!--            </td>-->
          <!--          </tr>-->
          <!--          <tr style="margin-top: 5px">-->
          <!--            <td>-->
          <!--              Phone：-->
          <!--            </td>-->
          <!--            <td>-->
          <!--              <el-input size="small" v-model="admin2.phone"></el-input>-->
          <!--            </td>-->
          <!--          </tr>-->
          <!--          <tr style="margin-top: 5px">-->
          <!--            <td>-->
          <!--              用户地址：-->
          <!--            </td>-->
          <!--            <td>-->
          <!--              <el-input size="small" v-model="admin2.address"></el-input>-->
          <!--            </td>-->
          <!--          </tr>-->
        </table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">Cancel</el-button>
        <el-button size="small" type="primary" @click="updateAdminInfo">Confirm</el-button>
      </span>
    </el-dialog>
    <el-dialog
        title="修改密码"
        :visible.sync="passwordDialogVisible"
        width="30%">
      <div>
        <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
          <el-form-item label="旧密码" prop="oldPass">
            <el-input type="password" v-model="ruleForm.oldPass" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="新密码" prop="pass">
            <el-input type="password" v-model="ruleForm.pass" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="再次输入" prop="checkPass">
            <el-input type="password" v-model="ruleForm.checkPass" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')">Submit</el-button>
            <el-button @click="resetForm('ruleForm')">Reset</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "AdminInfo",
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.ruleForm.checkPass !== '') {
          this.$refs.ruleForm.validateField('checkPass');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      admin: null,
      admin2: null,
      dialogVisible: false,
      passwordDialogVisible: false,
      ruleForm: {
        pass: '',
        oldPass: '',
        checkPass: '',
      },
      rules: {
        pass: [{validator: validatePass, trigger: 'blur'}],
        checkPass: [{validator: validatePass2, trigger: 'blur'}],
        oldPass: [{validator: validatePass, trigger: 'blur'}]
      }
    }
  },
  mounted() {
    this.initAdmin();
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.ruleForm.adminId = this.admin.id;
          this.postRequest('/changePass', this.ruleForm).then(resp => {
            if (resp) {
              //更新密码成功后，退出登录
              this.postRequest('/logout');
              window.sessionStorage.removeItem('user');
              window.sessionStorage.removeItem('tokenStr');
              this.$store.commit('initRoutes', []);
              this.$router.replace('/');
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    showUpdatePasswordView() {
      this.passwordDialogVisible = true;
    },
    updateAdminInfo() {
      this.putRequest('/user/info1', this.admin2).then(resp => {
        if (resp) {
          this.dialogVisible = false;
          this.initAdmin();
        }
      })
    },
    initAdmin() {
      this.getRequest('/user/info').then(resp => {
        if (resp) {
          // console.log(resp);
          this.admin = resp;
          this.admin2 = Object.assign({}, this.admin);
        }
      })
    },
    showUpdateAdminInfoView() {
      this.dialogVisible = true;
    }
  }
}
</script>

<style>
.cardForUser div {
  margin-bottom: 6px;
  display: flex;
  justify-content: flex-start;
}
</style>