<template>
  <div>
    <div>你已经勾画了{{ user.count }}张图片</div>
    <div v-if="loading">
      <br>
      <p>正在加载每日数据，这可能需要一段时间...</p>
      <br>
      <el-skeleton :rows="3" animated :loading="loading" :count="5"/>
      <br>
    </div>
    <div v-if="user.type==='4'">
      <div v-for="(it,i) in data">
        <el-divider content-position="left">{{ i }}</el-divider>
        <div v-for="(v,k) in it">
          日期：{{ k }},数量：{{ v }}
        </div>
      </div>
    </div>
    <div v-if="user.type==='2'">
      <div v-for="(v,k) in data">
        <el-divider content-position="left">日期：{{ k }}</el-divider>
        勾画数量：{{ v }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyWork",
  data() {
    return {
      loading: true,
      user: JSON.parse(window.sessionStorage.getItem('user')),
      data: null,
    };
  },
  methods: {
    getDailyPrePersonCount() {
      if (this.user.type === '4')
        this.getRequest(`/mzfCheck/getDailyPrePersonCount/-1`).then(res => {
          this.data = res.obj;
          this.loading = false;
        })
      else
        this.getRequest(`/mzfCheck/getDailyPrePersonCount/${this.user.id}`).then(res => {
          this.data = res.obj;
          this.loading = false;
        })
    },
  },
  created() {
    this.getDailyPrePersonCount();
  }

}
</script>

<style scoped>

</style>
