<template>
<div>审核数据统计</div>
</template>
<script>
export default {
  name: "checkWork"
}
</script>
<style scoped>

</style>