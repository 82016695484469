<template>
  <div>
    <div v-for="(fileCounts, userName) in this.userData" :key="userName">
      <h3>勾画员：{{ userName }}</h3>
      <ul>
        <li v-for="(count, date) in fileCounts" :key="date">
          日期：{{ date }} 勾画数量：{{ count }}
        </li>
      </ul>
    </div>
    <br>
    <div v-for="(fileCounts, userName) in this.correctorData" :key="userName">
      <h3>审核员：{{ userName }}</h3>
      <ul>
        <li v-for="(count, date) in fileCounts" :key="date">
          日期：{{ date }} 审核数量：{{ count }}
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: "AllWork",
  data() {
    return {
      userData: [],
      correctorData: []
    }
  },
  methods: {
    getUserWork() {
      this.getRequest(`/user/manager/getUserCompleteState`).then(res => {
        this.userData = res;
        // console.log(res)
      })
    },
    getCorrectorWork() {
      this.getRequest(`/user/manager/getCorrectorCompleteState`).then(res => {
        this.correctorData = res;
      })
    }
  },
  mounted() {
    this.getUserWork();
    this.getCorrectorWork();
  }
}
</script>
<style scoped>

</style>