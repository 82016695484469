<template>
  <div>
    <div class="full-page" id="section2">
      <div class="page-section" ref="sections" @wheel="handleWheel">
        <!--第一页-->
        <div class="section" id="section1">
          <div class="header">
            <div class="logo">
              <!--<img src="../assets/img/1.jpg"/>-->
              <span style="color: white;font-size: 40px;margin-left: 10px">ZERO</span>
            </div>
            <div class="links">
              <el-link type="primary" underline @click="jumpToPart(0)">Home</el-link>
              <el-link type="primary" underline @click="jumpToPart(1)">About</el-link>
              <el-link type="primary" underline @click="jumpToPart(2)">Contact</el-link>
              <el-link type="primary" underline @click="jumpToLogin">Login</el-link>
              <!--                            <el-link type="primary" underline @click="downloadFile">Download</el-link>-->
              <el-button type="primary" round style="font-size: 25px"><a
                  href="/download"
                  style="color: white;text-decoration: none">Download</a></el-button>
            </div>
          </div>
          <div class="section-content">
            <!--            我的课题研究-->
            <br/>
            A Large-Scale Human
            <br/>
            Laparoscopic Multisurgical
            <br/>
            Pixel-Level Labeled Dataset
            <!--            <br/>-->
            <!--            Endoscope Image Public Dataset-->
          </div>
          <!--          <div style="position: absolute;  left: 45%;  bottom: 1%;">-->
          <!--            <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" class="beian-link">-->
          <!--              浙ICP备2023018534号-->
          <!--            </a>-->
          <!--          </div>-->
        </div>
        <!--第二页-->
        <div class="section">
          <el-container style="margin-top: 10px;margin-left: 50px;">
            <el-header style="width: 100%;margin-left: 80px">
              <el-row :gutter="70">
                <el-col :span="8">
                  <div class="numRollBlock">
                    <span class="num" style="margin-left: 10px">
                    <countTo :startVal='this.startNumber' :endVal='this.ParttargetNumber' :duration='1000'/>+
                    </span>
                    <br/>
                    <span class="numRoll-text">Number of parts</span>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="numRollBlock">
                    <span class="num" style="margin-left: 10px">
                    <countTo :startVal='this.startNumber' :endVal='this.StyletargetNumber' :duration='1500'/>+
                    </span>
                    <br/>
                    <span class="numRoll-text">Surgery type</span>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="numRollBlock">
                    <span class="num" style="margin-left: 10px">
                    <countTo :startVal='this.startNumber' :endVal='this.VideotargetNumber' :duration='2000'/>+
                    </span>
                    <br/>
                    <span class="numRoll-text">Number of High quality surgical videos</span>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="numRollBlock">
                    <span class="num" style="margin-left: 10px">
                    <countTo :startVal='this.startNumber' :endVal='this.ImgtargetNumber' :duration='3000'/>+
                    </span>
                    <br/>
                    <span class="numRoll-text">Number of images</span>

                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="numRollBlock">
                    <span class="num" style="margin-left: 10px">
                    <countTo :startVal='this.startNumber' :endVal='this.MasktargetNumber' :duration='5000'/>+
                    </span>
                    <br/>
                    <span class="numRoll-text">Number of masks</span>
                  </div>
                </el-col>
              </el-row>
            </el-header>
            <el-main class="gallery" style="margin-top: 120px;">
              <el-carousel class="carousel" height="45vh" :interval="4000">
                <el-carousel-item v-for="(item, index) in datasetImages1" :key="index">
                  <el-image style="height: 50vh;" :src="item" fit="cover"></el-image>
                </el-carousel-item>
              </el-carousel>
              <el-carousel class="carousel" height="45vh" :interval="4000" style="margin-left: 150px;">
                <el-carousel-item v-for="(item, index) in datasetImages2" :key="index">
                  <el-image style="height: 50vh;" :src="item" fit="cover"></el-image>
                </el-carousel-item>
              </el-carousel>
            </el-main>
            <el-footer style="display: flex;flex-direction: column;justify-content: space-between;height: 20vh">
              <div style="margin-bottom: auto;">
                <span
                    style="color: white;font-size: 40px;font-weight: bold;text-shadow: 2px 2px 4px #000000, -2px -2px 4px #ffffff;">About our website</span>
                <br/>
                <span style="color: white;">&nbsp;&nbsp;&nbsp;&nbsp;The Large-Scale Human Laparoscopic Multisurgical Pixel-Level Labeled Dataset aims to provide a resource for endoscopic surgery images that covers the most organs and surgical types in the world. The emergence of this database is conducive to promoting the integration of virtual reality technology in medical surgery, and has significant implications for intraoperative image adjustment and surgical target recognition in complex medical scenes.</span>
                <br/>
              </div>
              <div>
                <span
                    style="color: white;font-size: 40px;font-weight: bold;text-shadow: 2px 2px 4px #000000, -2px -2px 4px #ffffff;">What can you do with our endoscope net</span>
                <br/>
                <span style="color: white;">&nbsp;&nbsp;&nbsp;&nbsp;You can promote the integration of virtual reality technology in medical surgery, which is of great significance for intraoperative image adjustment and surgical target recognition in complex medical scenes. This dataset is conducive to the development of algorithms such as augmented reality, semantic segmentation, target recognition, and motion compensation during surgery.</span>
              </div>
            </el-footer>
          </el-container>
        </div>
        <!--第三页-->
        <div class="section">
          <el-carousel :interval="8000" type="card" height="500px" style="margin-top: 10%;margin-left: 10%;width: 80%">
            <el-carousel-item v-for="(item,index) in photos" :key="index">
              <div class="carousel-item-wrapper">
                <img :src="item.src" class="carImage" alt="">
                <div class="caption">{{ item.description }}</div>
              </div>
            </el-carousel-item>
          </el-carousel>
          <span
              style="color: white;font-size: 50px;margin-left: 25%;font-weight: bold;">Founder Of the LPLD Dataset</span>
          <br/>
          <!--          <el-row class="footer-bar">-->
          <!--            <el-col :span="24">-->
          <!--              <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" class="beian-link">-->
          <!--                浙ICP备2023018534号-->
          <!--              </a>-->
          <!--            </el-col>-->
          <!--          </el-row>-->
        </div>
      </div>
      <el-backtop target="#section1" :right="40" :bottom="40">
        <i class="el-icon-caret-top"></i>
      </el-backtop>
    </div>
  </div>
</template>

<script>
import countTo from 'vue-count-to';
import axios from "axios";

export default {
  components: {
    countTo,
  },
  data() {
    return {
      photos: [
        {
          src: require('@/assets/people/shuai.jpg'),
          title: 'Photo 1',
          description: 'Jianwei Shuai'
        },
        {
          src: require('@/assets/people/A-MM_Khin+Yone.jpg'),
          title: 'Photo 1',
          description: 'Khin Saw Yone'
        },
        {
          src: require('@/assets/people/Xueying Bao.jpg'),
          title: 'Photo 1',
          description: 'Xueying Bao'
        },
        {
          src: require('@/assets/people/Qihao Li.jpg'),
          title: 'Photo 1',
          description: 'Qihao Li'
        },
        {
          src: require('@/assets/people/Yinda Chen.jpg'),
          title: 'Photo 1',
          description: 'Yinda Chen'
        },
        {
          src: require('@/assets/people/Yi Wang.png'),
          title: 'Photo 1',
          description: 'Yi Wang'
        },
        {
          src: require('@/assets/people/Youhui Lin.png'),
          title: 'Photo 1',
          description: 'Youhui Lin'
        },
        // {
        //   src: require('@/assets/people/wang.jpg'),
        //   title: 'Photo 1',
        //   description: 'Zhouguang Wang'
        // },
        // {
        //   src: require('@/assets/people/zuo.jpg'),
        //   title: 'Photo 2',
        //   description: 'Zhigui Zuo'
        // },
        {
          src: require('@/assets/people/hu.jpg'),
          title: 'Photo 1',
          description: 'Xiaoqu Hu'
        },
        // {
        //   src: require('@/assets/people/zhao.jpg'),
        //   title: 'Photo 1',
        //   description: 'Qi Zhao'
        // },
        {
          src: require('@/assets/people/hao.jpg'),
          title: 'Photo 2',
          description: 'Hanman Chen'
        },
        {
          src: require('@/assets/people/xuan.jpg'),
          title: 'Photo 1',
          description: 'Fanxuan Chen'
        },
        {
          src: require('@/assets/people/zhang.jpg'),
          title: 'Photo 2',
          description: 'Chengxi Zhang'
        },
        // {
        //   src: require('@/assets/people/jin.jpg'),
        //   title: 'Photo 2',
        //   description: 'Jincen Shuai'
        // },
        // {
        //   src: require('@/assets/people/chao.jpg'),
        //   title: 'Photo 2',
        //   description: 'Chaoyi Wei'
        // },
        // {
        //   src: require('@/assets/people/shi.jpg'),
        //   title: 'Photo 2',
        //   description: 'Yi Shi'
        // },
        // {
        //   src: require('@/assets/people/ren.jpg'),
        //   title: 'Photo 1',
        //   description: 'Jiahang Ren'
        // },
      ],
      startNumber: 0,
      ImgtargetNumber: 0,
      MasktargetNumber: 0,
      ParttargetNumber: 0,
      StyletargetNumber: 0,
      VideotargetNumber: 0,
      PeopleNumber: 0,
      currentSection: 0,
      name: '',
      email: '',
      galleryImages: [
        {
          src: require('@/assets/testData/42_10366.jpg'),
          style: {
            // transform: 'rotate(10deg)',
            zIndex: 2,
            top: '20px',
            left: '20px',
          },
        },
        {
          src: require('@/assets/testData/42_10374.jpg'),
          style: {
            // transform: 'rotate(10deg)',
            zIndex: 2,
            top: '120px',
            left: '220px',
          },
        },
        {
          src: require('@/assets/testData/42_10400.jpg'),
          style: {
            // transform: 'rotate(10deg)',
            zIndex: 2,
            top: '220px',
            left: '280px',
          },
        },
      ],
      datasetImages1: [
        require('@/assets/testData/42_10366.jpg'),
        require('@/assets/testData/42_10374.jpg'),
        require('@/assets/testData/42_10400.jpg'),
      ],
      datasetImages2: [
        require('@/assets/testData/42_10366.png'),
        require('@/assets/testData/42_10374.png'),
        require('@/assets/testData/42_10400.png'),
      ],
    };
  },
  methods: {
    downloadFile() {
      axios({
        url: 'http://localhost:8080/download',
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'dataset(1).zip')
        document.body.appendChild(link)
        link.click()
      })
    },
    jumpToLogin() {
      this.$router.replace('/login');
    },
    jumpToPart(sectionId) {
      this.currentSection = sectionId;
      if (this.currentSection === 1) {
        this.rollNum();
      } else {
        this.ImgtargetNumber = 0;
        this.MasktargetNumber = 0;
        this.ParttargetNumber = 0;
        this.StyletargetNumber = 0;
        this.PeopleNumber = 0;
        this.VideotargetNumber = 0;
      }
      this.$refs.sections.style.transform = `translateY(-${sectionId * 100}vh)`; // 通过修改transform来实现滚动
    },
    rollNum() {
      this.ImgtargetNumber = 23000;
      this.MasktargetNumber = 37000;
      this.ParttargetNumber = 23;
      this.StyletargetNumber = 15;
      this.PeopleNumber = 1000;
      this.VideotargetNumber = 350;
    },
    handleWheel(e) {
      const direction = e.deltaY > 0 ? 1 : -1;
      this.currentSection += direction;
      this.currentSection = Math.min(Math.max(this.currentSection, 0), 2); // 确保在0-2之间
      this.$refs.sections.style.transform = `translateY(-${this.currentSection * 100}vh)`;
      if (this.currentSection === 1) {
        this.rollNum();
      } else {
        this.ImgtargetNumber = 0;
        this.MasktargetNumber = 0;
        this.ParttargetNumber = 0;
        this.StyletargetNumber = 0;
        this.PeopleNumber = 0;
        this.VideotargetNumber = 0;
      }
    },
    // onSubmit() {
    //   // 提交表单的代码...
    // },
  },
  mounted() {
    window.onload = function () {
      if (typeof window.URL.createObjectURL === 'undefined') {
        document.querySelector('.upgrade-browser').style.display = 'block';
      }
    };
  }
};
</script>

<style scoped>
@font-face {
  font-family: "JetBrains Mono";
  src: url("../assets/fonts/JetBrainsMono-Regular.ttf") format("truetype");
}

* {
  font-family: "JetBrains Mono", monospace;
}

.full-page {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.page-section {
  width: 100%;
  height: 300vh; /* 3个section */
  transition: transform 0.5s ease-out;
}

#section1 {
  background-image: url('../assets/img/3.jpg');
  background-size: cover;
  background-position: center center;
  position: relative; /* 这是新添加的，以便能在这个元素里进行绝对定位 */
}

#section2 {
  background-image: url('../assets/img/blurred_image.jpg');
  background-size: cover;
  background-position: center center;
  position: relative; /* 这是新添加的，以便能在这个元素里进行绝对定位 */
}

.section-content {
  position: absolute;
  left: 60px;
  bottom: 250px;
  color: white;
  font-size: 73px;
  text-shadow: 2px 2px 4px #000000, -2px -2px 4px #ffffff;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background: rgba(0, 0, 0, 0.5);
}

.logo img {
  height: 50px; /* 设置Logo的大小 */
}

.links .el-link {
  margin-left: 20px; /* 设置链接间的间距 */
  color: white;
  margin-right: 100px;
  font-size: 25px;
  font-weight: bold;
}

.section {
  width: 100%;
  height: 100vh;
  position: relative;
}


.carousel {
  width: 45%;
  border-radius: 10px;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.gallery {
  display: flex;
  justify-content: center;
  align-items: center;
}

.numRollBlock {
  margin-right: 20px;
}

.num {
  font-size: 50px;
  color: rgba(255, 255, 255, 0.81);
  text-shadow: 2px 2px 4px #000000, -2px -2px 4px #ffffff;
  font-weight: bold;
}

.numRoll-text {
  margin-left: 10px;
  font-size: 20px;
  color: rgba(255, 255, 255, 0.82);
  font-weight: bold;
}

.carousel-item-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}


.carImage {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.caption {
  margin-top: 225px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  text-align: center;
  font-size: 25px;
  text-shadow: 2px 2px 2px #000000;
  color: rgba(255, 255, 255, 0.81);
  font-weight: bold;
}

.beian-link {
  color: white;
  text-align: center;
  display: block;
  text-decoration: none;
}
</style>
